// @ts-strict-ignore
import {
  LanguageCodeEnum,
  usePromotionTranslationDetailsQuery,
  useUpdatePromotionRuleTranslationsMutation,
  useUpdatePromotionTranslationsMutation,
} from "@dashboard/graphql";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import useShop from "@dashboard/hooks/useShop";
import { commonMessages } from "@dashboard/intl";
import { extractMutationErrors } from "@dashboard/misc";
import TranslationsPromotionsPage from "@dashboard/translations/components/TranslationsPromotionsPage";
import { stringifyQs } from "@dashboard/utils/urls";
import { OutputData } from "@editorjs/editorjs";
import React from "react";
import { useIntl } from "react-intl";

import { TranslationField, TranslationInputFieldName } from "../types";
import { getParsedTranslationInputData } from "../utils";

type HandleSubmitRule = OutputData | string;

export interface TranslationsPromotionsQueryParams {
  activeField: string;
}
export interface TranslationsPromotionsProps {
  id: string;
  languageCode: LanguageCodeEnum;
  params: TranslationsPromotionsQueryParams;
}

const TranslationsPromotions: React.FC<TranslationsPromotionsProps> = ({
  id,
  languageCode,
  params,
}) => {
  const navigate = useNavigator();
  const notify = useNotifier();
  const shop = useShop();
  const intl = useIntl();
  const promotionTranslations = usePromotionTranslationDetailsQuery({
    variables: { id, language: languageCode },
  });
  const onUpdate = (errors: unknown[]) => {
    if (errors.length === 0) {
      promotionTranslations.refetch();
      notify({
        status: "success",
        text: intl.formatMessage(commonMessages.savedChanges),
      });
      navigate("?", { replace: true });
    }
  };
  const [updateTranslations, updateTranslationsOpts] = useUpdatePromotionTranslationsMutation({
    onCompleted: data => onUpdate(data.promotionTranslate.errors),
  });
  const [updateRuleTranslations] = useUpdatePromotionRuleTranslationsMutation({
    onCompleted: data => onUpdate(data.promotionRuleTranslate.errors),
  });

  const onEdit = (field: string) =>
    navigate(
      "?" +
        stringifyQs({
          activeField: field,
        }),
      { replace: true },
    );
  const onDiscard = () => {
    navigate("?", { replace: true });
  };
  const handleSubmit = (
    { name: fieldName }: TranslationField<TranslationInputFieldName>,
    data: string,
  ) =>
    extractMutationErrors(
      updateTranslations({
        variables: {
          id,
          input: getParsedTranslationInputData({
            data,
            fieldName,
          }),
          language: languageCode,
        },
      }),
    );

  const handleRuleSubmit = (
    { id, name }: TranslationField<TranslationInputFieldName>,
    data: HandleSubmitRule,
  ) =>
    extractMutationErrors(
      updateRuleTranslations({
        variables: {
          id,
          input: {
            [name.split(":")[1]]: data,
          },
          language: languageCode,
        },
      }),
    );
  const translation = promotionTranslations?.data?.translation;

  return (
    <TranslationsPromotionsPage
      translationId={id}
      activeField={params.activeField}
      disabled={promotionTranslations.loading || updateTranslationsOpts.loading}
      languages={shop?.languages || []}
      languageCode={languageCode}
      saveButtonState={updateTranslationsOpts.status}
      onEdit={onEdit}
      onDiscard={onDiscard}
      onSubmit={handleSubmit}
      onRuleSubmit={handleRuleSubmit}
      data={translation?.__typename === "PromotionTranslatableContent" ? translation : undefined}
    />
  );
};

TranslationsPromotions.displayName = "TranslationsPromotions";
export default TranslationsPromotions;
