import { useCallback, useState } from "react";

export function useSearchRequires<T extends Record<string, boolean>>(initialState: T) {
  const [listRequired, setListRequired] = useState<T>(initialState);

  const isSearchRequired = useCallback(
    (key: keyof T) => {
      return listRequired[key];
    },
    [listRequired],
  );

  const setSearchRequired = useCallback((key: keyof T) => {
    setListRequired(prev =>
      prev[key]
        ? prev
        : {
            ...prev,
            [key]: true,
          },
    );
  }, []);

  return { isSearchRequired, setSearchRequired };
}
