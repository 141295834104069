import { AttributeEntityTypeEnum } from "@dashboard/graphql";
import { useSearchRequires } from "@dashboard/hooks/useSearchRequires";
import { useCallback } from "react";

export function useEntitySearchRequires<
  T extends Record<"pages" | "products" | "variants", boolean>,
>(initialState?: Partial<T>) {
  const { isSearchRequired, setSearchRequired } = useSearchRequires({
    pages: initialState?.pages ?? false,
    products: initialState?.products ?? false,
    variants: initialState?.variants ?? false,
  });

  const setEntityTypeRequired = useCallback(
    (entityType: AttributeEntityTypeEnum) => {
      switch (entityType) {
        case AttributeEntityTypeEnum.PAGE:
          setSearchRequired("pages");
          break;
        case AttributeEntityTypeEnum.PRODUCT:
          setSearchRequired("products");
          break;
        case AttributeEntityTypeEnum.PRODUCT_VARIANT:
          setSearchRequired("variants");
          break;
      }
    },
    [setSearchRequired],
  );

  return { isSearchRequired, setEntityTypeRequired };
}
