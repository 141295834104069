import { defineMessages } from "react-intl";

export const messages = defineMessages({
  installAppDescription: {
    id: "ARfw53",
    defaultMessage: "Install apps created by Saleor team or install external apps.",
    description: "description",
  },
  installedApps: {
    id: "/PhkXi",
    defaultMessage: "Installed Apps",
    description: "section header",
  },
  ongoingInstallations: {
    id: "nIrjSR",
    defaultMessage: "Ongoing Installations",
    description: "section header",
  },
  allApps: {
    id: "yZB41s",
    defaultMessage: "All Apps",
    description: "section header",
  },
  comingSoonApps: {
    id: "hJDnLg",
    defaultMessage: "Upcoming Apps",
    description: "section header",
  },
  missingAppsHeader: {
    id: "Ntk3CQ",
    defaultMessage: "Missing something?",
    description: "footer title",
  },
  missingAppsDescription: {
    id: "kyNv+Y",
    defaultMessage: "Are we missing an integration your project requires? Let us know here",
    description: "footer description",
  },
  missingAppsButton: {
    id: "VlDG1B",
    defaultMessage: "Request integration",
    description: "Request integration",
  },
});
