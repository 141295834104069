import {
  IMessage,
  IMessageContext,
  MessageContext,
} from "@dashboard/components/messages";
import { useCallback, useContext } from "react";

export type UseNotifierResult = IMessageContext;

function useNotifier(): UseNotifierResult {
  const notificationContext = useContext(MessageContext);

  const notify = useCallback(
    (options: IMessage) => {
      const timeout = options.status === "error" ? null : options.autohide;
      notificationContext?.show(options, timeout);
    },
    [notificationContext?.show],
  );

  return notify;
}
export default useNotifier;
