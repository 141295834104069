import { useDiscountRulesContext } from "@dashboard/discounts/components/DiscountRules/context";
import { Rule } from "@dashboard/discounts/models";
import { RewardTypeEnum } from "@dashboard/graphql";
import { Box, Checkbox, Text } from "@saleor/macaw-ui-next";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { messages } from "../../../../messages";
import { RuleInputWrapper } from "../RuleInputWrapper/RuleInputWrapper";

export const RuleExcludeCataloguePromotionsCheckbox: React.FC = () => {
  const { disabled } = useDiscountRulesContext();
  const {
    field,
    fieldState: { error },
  } = useController<Rule, "excludeCataloguePromotions">({
    name: "excludeCataloguePromotions",
  });

  const { watch } = useFormContext<Rule>();
  const rewardType = watch("rewardType");

  if (rewardType !== RewardTypeEnum.SUBTOTAL_DISCOUNT) {
    return null;
  }

  return (
    <Box overflow="hidden">
      <RuleInputWrapper>
        <Checkbox
          name="excludeCataloguePromotions"
          data-test-id="exclude-catalogue-promotions-checkbox"
          checked={field.value}
          error={!!error}
          onCheckedChange={value =>
            field.onChange({ target: { name: "excludeCataloguePromotions", value } })
          }
          disabled={disabled}
        >
          <Text>
            <FormattedMessage {...messages.excludeCataloguePromotions} />
          </Text>
        </Checkbox>
      </RuleInputWrapper>
    </Box>
  );
};
